
const fbID = process.env.REACT_APP_FACEBOOK_APP_ID;


function InitFacebookSdk() {
  

  return (
     new Promise(resolve => {
      // wait for facebook sdk to initialize before starting the react app
      window.fbAsyncInit = function () {
          window.FB.init({
              appId: fbID,
              cookie: true,
              xfbml: true,
              version : 'v15.0',
          });
      };


      if (!document.getElementById("fb-root")) {
        // create div required for fb
        const fbDiv = document.createElement("div");
        fbDiv.id = "fb-root";
        document.body.appendChild(fbDiv);
        // Run any script after sdk is loaded
        window.fbAsyncInit = () => {
          //
        };
        // inject sdk.js
        (function(d, script) {
          script = d.createElement("script");
          script.type = "text/javascript";
          script.async = true;
          script.src =
            "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2&appId=" +
            fbID+
            "&autoLogAppEvents=1";
          d.getElementsByTagName("head")[0].appendChild(script);
        })(document);
      }
      //load facebook sdk script
      // (function (d, s, id) {
      //     var js,
      //       fjs = d.getElementsByTagName(s)[0];
      //     if (d.getElementById(id)) {
      //       return;
      //     }
      //     js = d.createElement(s);
      //     js.id = id;
      //     js.src = "//connect.facebook.net/zh_TW/all.js";;
      //     fjs.parentNode.insertBefore(js, fjs);
      //   })(document, 'script', 'facebook-jssdk');
  }))
  
};

export default InitFacebookSdk;